/** @jsx jsx */
import { jsx } from 'theme-ui'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './Serializers'

const PortableText = ({ className, isKorean, insidePost, blocks }) => {
  const style = {
    p: 6,
    '& *': {
      fontSize: 'lg',
    },
    '& a': {
      wordWrap: 'break-word',
      color: 'blue',
      textDecoration: 'none',
      opacity: 0.9,

      '&:hover': {
        color: 'blue',
        textDecoration: 'underline',
        opacity: 1,
      },
    },
    '& p, & li': {
      lineHeight: isKorean ? 'relaxed' : 'normal',
    },
    '& h2': { fontSize: '3xl' },
    '& h3': { fontSize: '2xl' },
    '& h4': { fontSize: 'xl' },
    '& h5': { fontSize: 'lg' },
    '& h6': { fontSize: 'base' },
    '& h2, & h3, & h4, & h5, & h6': {
      fontWeight: 'semibold',
      lineHeight: 'tight',
      color: 'black',
      opacity: 0.85,
      mt: 12,
      mb: 4,
    },
    '& ul, & ol': { pl: 8, my: 4 },
    '& li > ul, & li > ol': { my: 0 },
    '& blockquote': {
      my: 4,
      mx: 2,
      px: 3,
      py: 1,
      borderLeft: 'thickLightOrange',
    },
    '& strong': {
      fontWeight: 'semibold',
    },
    '& figure': {
      m: 0,
      p: 0,

      '& img': {
        maxWidth: 'full',
      },
    },
  }
  return (
    <div className={className} sx={insidePost ? style : {}}>
      <BasePortableText blocks={blocks} serializers={serializers} {...clientConfig.sanity} />
    </div>
  )
}

export default PortableText

/** @jsx jsx */
import { jsx } from 'theme-ui'
import { TwitterTweetEmbed } from 'react-twitter-embed'

export default ({ url }) => {
  if (!url) {
    return null
  }
  const id = /\/status\/(\d+)/.exec(url)[1]
  return <TwitterTweetEmbed tweetId={id} />
}

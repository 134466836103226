/** @jsx jsx */
import { jsx } from 'theme-ui'

export default ({ code }) => (
  <code
    sx={{
      fontSize: 'sm',
      fontWeight: 'normal',
      border: 'lightBlack',
      borderRadius: 'base',
      py: '0px',
      px: '4px',
      mx: '2px',
      color: 'orange',
    }}
  >
    {code}
  </code>
)

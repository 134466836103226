/** @jsx jsx */
import { jsx } from 'theme-ui'

export default ({ space }) => (
  <div
    sx={{
      height: space,
    }}
  />
)

import React from 'react'
import NormalImage from './NormalImage'
import InlineCode from './InlineCode'
import Tweet from './Tweet'
import Alert from './Alert'
import Code from './Code'
import Hr from './Hr'
import Space from './Space'

const serializers = {
  types: {
    normalImage: ({ node }) => <NormalImage {...node} />,
    inlineCode: ({ node }) => <InlineCode {...node} />,
    twitter: ({ node }) => <Tweet {...node} />,
    alert: ({ node }) => <Alert {...node} />,
    code: ({ node }) => <Code {...node} />,
    hr: () => <Hr />,
    space: ({ node }) => <Space {...node} />,
  },
}

export default serializers

/** @jsx jsx */
import { jsx } from 'theme-ui'

export default ({ title, text, color }) => (
  <div
    sx={{
      borderLeftColor: color,
      borderLeftWidth: '2px',
      borderLeftStyle: 'solid',
      my: 4,
      mx: 2,
      px: 3,
      py: 1,
    }}
  >
    <p
      sx={{
        color,
        fontWeight: 'semibold',
        mt: 0,
        mb: 2,
      }}
    >
      {title}
    </p>{' '}
    <p sx={{ m: 0 }}>{text}</p>
  </div>
)

/** @jsx jsx */
import { jsx } from 'theme-ui'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/dracula'

export default ({
  code,
  lang,
  title,
  showLineNumbers,
  lineStartNumber = 1,
  lineHighlight = '',
}) => {
  if (!code) {
    return null
  }
  const ranges = lineHighlight
    .split(',')
    .filter(Boolean)
    .map(range => (range.indexOf('-') >= 0 ? range.split('-') : [range, range]))
  const shouldHighlight = number =>
    Boolean(ranges.find(range => range[0] <= number && number <= range[1]))

  return (
    <div
      sx={{
        '& pre': { py: 4, borderRadius: 'md', overflowX: 'auto' },
        '& pre > .token-line': { px: 4 },
      }}
    >
      <Highlight {...defaultProps} theme={theme} code={code} language={lang}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {title && (
              <p
                sx={{
                  mt: 0,
                  px: 6,
                  pb: 4,
                  borderBottom: 'lightBlue',
                  borderWidth: '2px',
                }}
              >
                {title}
              </p>
            )}
            {tokens.map((line, i) => (
              <div
                {...getLineProps({ line, key: i })}
                sx={{
                  ...(shouldHighlight(i + lineStartNumber) && {
                    backgroundColor: 'rgb(61, 71, 119)',
                  }),
                }}
              >
                {showLineNumbers && (
                  <span
                    sx={{
                      display: 'inline-block',
                      width: '2.1rem',
                      textAlign: 'right',
                      paddingRight: '1em',
                      userSelect: 'none',
                      opacity: 0.5,
                    }}
                  >
                    {i + lineStartNumber}
                  </span>
                )}
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}

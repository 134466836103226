/** @jsx jsx */
import { jsx } from 'theme-ui'

export default () => (
  <hr
    sx={{
      my: 6,
      borderTop: '0',
      borderBottom: 'lightBlack',
    }}
  />
)

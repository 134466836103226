/** @jsx jsx */
import { jsx } from 'theme-ui'
import Figure from './Figure'

export default ({ image, caption, url, align = 'center', percentage, showOriginal }) => {
  let percentageArray = ['100%']
  try {
    if (percentage) {
      percentageArray = JSON.parse(percentage)
    }
  } catch (e) {}
  const imageElem = (
    <div
      sx={{
        my: 4,
        display: 'flex',
        justifyContent: align,
      }}
    >
      <div
        sx={{
          width: percentageArray,
        }}
      >
        {showOriginal ? (
          <img src={image.asset.url} sx={{ width: percentageArray }} />
        ) : (
          <Figure node={image} />
        )}
        {caption && (
          <p
            sx={{
              textAlign: 'center',
              mt: 2,
              color: 'gray6',
            }}
          >
            ↑ {caption}
          </p>
        )}
      </div>
    </div>
  )

  return !url ? (
    imageElem
  ) : (
    <a href={url} target="_blank">
      {imageElem}
    </a>
  )
}
